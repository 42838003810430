import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 27,
    title: "Come Integrare i Pagamenti Stripe con React",
    desc: "",
    img: "/blog-image/stripe.png",
    page: "blog/react-stripe",
    data: "11 Ott 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Stripe è una delle piattaforme di pagamento più popolari e affidabili. Consente alle piattaforme di accettare pagamenti online in modo sicuro e affidabile.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        Quasi nessuna web application
                                        finalizzata alla monetizzazione può fare
                                        a meno di integrare i pagamenti in
                                        un'applicazione we. Stripe è una delle
                                        piattaforme di pagamento più popolari e
                                        affidabili. Consente alle piattaforme di
                                        accettare pagamenti online in modo
                                        sicuro e affidabile. In questo tutorial,
                                        ti guideremo attraverso il processo di
                                        integrazione dei pagamenti Stripe in
                                        un'app React.
                                    </p>

                                    <ol>
                                        <li>
                                            <h3>
                                                Passo 1: Creazione di un Account
                                                Stripe
                                            </h3>
                                            <p>
                                                Prima di iniziare, dovrai creare
                                                un account su Stripe. Una volta
                                                effettuato l'accesso, sarai in
                                                grado di ottenere le chiavi API
                                                necessarie per l'integrazione
                                                dei pagamenti.
                                            </p>
                                        </li>
                                        <li>
                                            <h3>
                                                Passo 2: Configurazione di un
                                                Progetto React
                                            </h3>
                                            <p>
                                                Se non hai già un progetto
                                                React, puoi crearne uno
                                                utilizzando Create React App.
                                                Assicurati di avere Node.js e
                                                npm installati sul tuo sistema.
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`npx create-react-app stripe-payment-demo
cd stripe-payment-demo`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li>
                                            <h3>
                                                Passo 3: Installazione della
                                                Libreria Stripe
                                            </h3>
                                            <p>
                                                Per utilizzare Stripe nella tua
                                                app React, dovrai installare la
                                                libreria Stripe. Esegui il
                                                seguente comando nella radice
                                                del tuo progetto:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`npm install react-stripe-elements`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <br />
                                        <br />
                                        {isMobile ? (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? <br />
                                                        <span
                                                            style={{
                                                                fontSize: 20,
                                                            }}
                                                        >
                                                            Clicca qui
                                                        </span>
                                                    </Link>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? Clicca
                                                        qui
                                                    </Link>
                                                </div>
                                            </>
                                        )}
                                        <br />
                                        <li>
                                            <h3>
                                                Passo 4: Configurazione delle
                                                Chiavi API di Stripe
                                            </h3>
                                            <p>
                                                Nel tuo progetto, crea un file
                                                di configurazione (ad esempio
                                                stripeConfig.js) per archiviare
                                                le tue chiavi API di Stripe.
                                                Sarà importante tenerle al
                                                sicuro e non condividerle
                                                pubblicamente. Il tuo file di
                                                configurazione dovrebbe avere un
                                                aspetto simile a questo:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`// stripeConfig.js
const STRIPE_PUBLISHABLE_KEY = 'la_tua_chiave_pubblicabile';
const STRIPE_SECRET_KEY = 'la_tua_chiave_segreta';

export { STRIPE_PUBLISHABLE_KEY, STRIPE_SECRET_KEY };`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li>
                                            <h3>
                                                Passo 5: Creazione di un
                                                Componente di Pagamento
                                            </h3>
                                            <p>
                                                Ora puoi creare un componente
                                                React per gestire il pagamento.
                                                Ad esempio, potresti chiamarlo{" "}
                                                <b>PaymentForm.js</b>.
                                                All'interno di questo
                                                componente, userai{" "}
                                                <b>react-stripe-elements</b> per
                                                integrare la funzionalità di
                                                pagamento di Stripe.
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`// PaymentForm.js
import React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';

class PaymentForm extends React.Component {
    handleSubmit = async (event) => {
        event.preventDefault();

        const { token } = await this.props.stripe.createToken();

        if (token) {
            // Invia il token al tuo server per elaborare il pagamento
            console.log('Token:', token);
        } else {
            console.log('Errore nella creazione del token.');
        }
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <label>
                    Dettagli della carta
                    <CardElement />
                </label>
                <button type="submit">Paga</button>
            </form>
        );
    }
}

export default injectStripe(PaymentForm);`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li>
                                            <h3>
                                                Passo 6: Utilizzo del Componente
                                                di Pagamento
                                            </h3>
                                            <p>
                                                Ora puoi utilizzare il
                                                componente PaymentForm nella tua
                                                applicazione React. Ad esempio,
                                                potresti includerlo in un
                                                componente di checkout.
                                                Assicurati di importare i
                                                componenti <b>Elements</b> e{" "}
                                                <b>StripeProvider</b> da{" "}
                                                <b>react-stripe-elements</b> nel
                                                tuo componente principale:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`// App.js
import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import PaymentForm from './PaymentForm';
import { STRIPE_PUBLISHABLE_KEY } from './stripeConfig';

class App extends React.Component {
    render() {
        return (
            <StripeProvider apiKey={STRIPE_PUBLISHABLE_KEY}>
                <div className="App">
                    <Elements>
                        <PaymentForm />
                    </Elements>
                </div>
            </StripeProvider>
        );
    }
}

export default App;
`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li>
                                            <h3>
                                                Passo 7: Gestione delle
                                                Transazioni sul Server
                                            </h3>
                                            <p>
                                                Una volta che un utente
                                                inserisce i dettagli della carta
                                                e fa clic su "Paga", Stripe
                                                restituirà un token che dovrai
                                                inviare al tuo server per
                                                elaborare la transazione. Sul
                                                tuo server, userai le chiavi API
                                                segrete di Stripe per confermare
                                                e completare il pagamento.
                                                Questa parte richiede un
                                                back-end per l'elaborazione dei
                                                pagamenti e può variare a
                                                seconda delle tue esigenze
                                                specifiche. Vedremo di trattare
                                                questa parte di un articolo
                                                separato prossimamente.
                                            </p>
                                        </li>
                                    </ol>
                                    <p>
                                        Con l'integrazione di Stripe nella tua
                                        app, puoi accettare pagamenti online in
                                        modo semplice e sicuro. Questo tutorial
                                        ti ha guidato attraverso i passaggi
                                        principali, ma tieni presente che ci
                                        sono molti dettagli tecnici da
                                        considerare quando si implementa un
                                        sistema di pagamento completo.
                                        Assicurati di consultare la{" "}
                                        <a
                                            href="https://stripe.com/docs"
                                            target="_blank"
                                            rel=""
                                        >
                                            documentazione di Stripe
                                        </a>{" "}
                                        e di effettuare test approfonditi prima
                                        di rendere il tuo sistema di pagamento
                                        disponibile al pubblico. Se hai bisogno
                                        di un supporto da parte di esperti puoi
                                        sempre contare su di noi. Buon coding a
                                        tutti!
                                    </p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>
                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};

export default BlogDetails;
